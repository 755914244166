import React from "react"
import YAMLContent from "../content/404.yaml"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Page404 from "../components/Page404/Page404"
import LazyHydrate from "react-lazy-hydration"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <LazyHydrate ssrOnly>
      <Page404 title={YAMLContent.title} button={YAMLContent.button} />
    </LazyHydrate>
  </Layout>
)

export default NotFoundPage
