import React from "react"

// Styles
import * as styles from "./Page404.module.sass"

import Container from "../../ui-kit/Container/Container"
import Row from "../../ui-kit/Row/Row"

import Button from "../../ui-kit/Button/Button"
import Ventels from "../../icons/light/ventels2.svg"
import VentelsDark from "../../icons/dark/ventels2.svg"
import { useCurrentTheme } from "../../utils/themeChanger"

const Page404 = ({ title, button }) => {
  const colorTheme = useCurrentTheme()
  const isLightTheme = colorTheme.data?.theme === "light"

  return (
    <div className={styles.content}>
      <Container>
        <Row>
          <div className={styles.leftBlock}>
            <img alt="Ventels" src={isLightTheme ? Ventels : VentelsDark} />
          </div>
          <div className={styles.rightBlock}>
            <h1>404</h1>
            <p>{title}</p>
            <div className={styles.button}>
              <Button link="/">{button}</Button>
            </div>
          </div>
        </Row>
      </Container>
    </div>
  )
}

export default Page404
